'use strict';

var dialog = require('./dialog'),
    page = require('./page'),
    util = require('./util'),
    ajax = require('./ajax'),
    tabs = require('./tabs'),
	validator = require('./validator'),
	enhancedSignin = SitePreferences.ENNANCED_SIGNIN ? require('./pages/enhancedSignin/accountlogin') : null;

var login = {
		
	 siginOverLay: function(element) {
		    $('button[name$="_login_login"]').off("click").on("click", function(e) {
		        e.preventDefault();
		        if (element == undefined) {
		        	element = $('a.account-signin');
		        } 

		        var $curForm = $(this).closest("form");
		        $curForm.validate();
		        if (!$curForm.valid()) {
		            return false;
		        }
		        var url = $curForm.attr('action');
		        ajax.load({
		            url: url,
		            type: 'POST',
		            data: $curForm.serialize(),
		            callback: function(data) {
		                var $userAgreementModal = $(data).find('div.user-shopping-terms');
		                if ($userAgreementModal.length > 0) {
		                	$('.ui-widget-overlay').css('zIndex', '9999');
		                	$('div.account-login-dialog').find('.ui-dialog-titlebar-close').hide();
		                	login.handleUserAgreementModal($userAgreementModal,element,url);
		                } else {
		                	login.handelWishListConfirmationMsg(data,element,url);
		                }
		            }
		        });
			});

		    $(document).on('click', '.global-account-modal .apply-button.createAccount', function (e) {   
		        e.preventDefault();
		        var $curForm = $(this).closest("form");
		        $curForm.validate();
		        if (!$curForm.valid()) {
		            return false;
		        }
		        var url = util.appendParamToURL($curForm.attr('action'), $(this).attr('name'), true);
					url = util.appendParamToURL(url, 'scope', 'forYou');
					
		        $.ajax({
		            url: util.appendParamToURL(url, 'format', 'ajax'),
		            data: $curForm.serialize(),
		            type: 'POST',
		            success: function(response) {
		            	if ($(response).find('.registered-nav-list').length > 0 || response.status === 'true') {
		            		location.href = util.appendParamToURL(Urls.homeShow, 'AccountCreated', 'forYou');
		                } else if (response.status) {
		                	if(response.status === 'accountexist'){
		                		$('[class*="profile_customer_email"] .field-wrapper').append('<span class="error">' + Resources.EXISTING_CUSTOMER + '</span>');
		                  		return false;
		                    } else if (response.status === 'existing') {
		                    	$('.pw-container .field-wrapper').append('<span class="error">' + Resources.PASSWORD_INVALID_ERROR + '</span>');
		                  		return false;  
		                    }
		                }
		            }
		        });
		    });
		    tabs.init();
	},
	
	handleUserAgreementModal: function ($userAgreementModal,element,loginLocation) {
	    dialog.replace({
	        html : $userAgreementModal,
	        callback : function () {
	            $userAgreementModal.find('button.accept-button, button.decline-button').off("click").on("click", function(e) {
	                e.preventDefault();
	                if (loginLocation.indexOf('wishlistHeader') > 0) {
	                	var returnUrl = util.appendParamToURL(Urls.wishlistShow,'scope','wishlistHeader');
	                }
	                var $curForm = $(this).closest("form");
	                var url = $curForm.attr('action');
	                var $hiddenInput = jQuery('<input/>', {
	                    type : 'hidden',
	                    name : e.target.name,
	                    value : e.target.value,
	                    'aria-invalid' : 'false'
	                });
	                $curForm.append($hiddenInput);
	                ajax.load({
	                    url: url,
	                    type: 'POST',
	                    data: $curForm.serialize(),
	                    callback: function(data) {
	                        login.handelWishListConfirmationMsg(data,element,returnUrl);
	                    }
	                });
	            });
	        }
	    });
	},

	handelWishListConfirmationMsg: function (data,element,url) {
		if ($(data).find('.error-form').length > 0 || $(data).find('.error-message').length > 0) {
			if ($(data).find('.out-of-stock-error').length > 0) {
	        	location.href = util.appendParamToURL(Urls.cartShow, 'cartInventoryError', 'cartInventoryError');
	        } else {
	        	var accountDialog = $('div.account-login-dialog');
	        	if (accountDialog.find('.wishlist-favorites').length > 0) {
					accountDialog.find('.wishlist-favorites').html(data);
				} else if (accountDialog.find('.login-account').length > 0 ) {
	        		var loginForm = $(data).find('.login-account');
	        		accountDialog.find('.login-account').html(loginForm);
	        	} else {
	        		accountDialog.find('div.modal-login').html(data);
	        	}
		        validator.init();
		        login.resetPasswordModal();
		        login.siginOverLay(element);
	        }
	    } else {
	    	var getQueryString = util.getQueryString(url);
        	var getQueryStringParam = util.getQueryStringParams(getQueryString);
        	
            if (location.href.indexOf('SingleShipping') > 0 || location.href.indexOf('checkoutshipping') > 0) {
	    		location.href = util.appendParamToURL(Urls.checkout,'shipping', 'MultiShippingAddresses');
            } else if (location.href.indexOf('shipping') > 0 ) {
	    		location.href = Urls.checkout;
	    	} else if (getQueryStringParam.scope === 'wishlistHeader') {
				location.href = util.appendParamToURL(Urls.wishlistShow,'LoginLocation','wishlistHeader');
			} else if (getQueryStringParam.scope === 'forYou') {
	    		location.href = util.appendParamToURL(Urls.homeShow, 'LoginLocation', 'forYou');
	    	} else {
				page.refresh();
	    	}
	    }
	},
	
	resetPasswordCallback: function() {
        validator.init();
        var $requestPasswordForm = $('[name$="_requestpassword"]');
        var $submit = $requestPasswordForm.find('[name$="_requestpassword_send"]');
        $($submit).on('click', function (e) {
            if (!$requestPasswordForm.valid()) {
                return;
            }
            e.preventDefault();
            //Iframe
            var realForm = $(this).closest('form');
            var iframeId = 'reset-password-iframe';
            var formId = 'reset-password-form';
            jQuery('#' + iframeId).remove();
            jQuery('#' + formId).remove();
            var $form = jQuery('<form></form>');
            $form.attr('id', formId);
            $form.attr('action', realForm.attr('action'));
            $form.attr('target', iframeId);
            $form.attr('method', 'POST');

            jQuery('<iframe name="' + iframeId + '" id="' + iframeId + '" />').appendTo('body');
            jQuery('#' + iframeId).attr('style', 'display: none;');

            realForm.find('input, select, textarea, button').each(function(){
                var $input = $(this).clone();
                $form.append($input);
            });
            jQuery('#' + iframeId).contents().find('body').append($form);
            var form = jQuery('#' + iframeId).contents().find('form')
            //Iframe
            
            var data = form.serialize();
            // add form action to data
            data += '&' + $submit.attr('name') + '=';
            // make sure the server knows this is an ajax request
            if (data.indexOf('ajax') === -1) {
                data += '&format=ajax';
            }
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: data,
                async : false,
                crossDomain  : true,
                xhrFields: {
                    withCredentials: true
                },
                success: function (response) {
                    if (typeof response === 'object' &&
                            !response.success &&
                            response.error === 'CSRF Token Mismatch') {
                        page.redirect(Urls.csrffailed);
                    } else if (typeof response === 'string') {
                        dialog.$container.html(response);
                    }
                },
                failure: function () {
                    dialog.$container.html('<h1>' + Resources.SERVER_ERROR + '</h1>');
                }
            });
        });
	},
	
	resetPasswordModal: function() {
	    $('.login-passwordreset a').off('click').on('click', function(e) {
	        e.preventDefault();
	        ajax.load({
	        	url: $(e.target).attr('href'),
	            async: false,
	            type: 'GET',
	            callback: function(data) {
	                $('div.account-login-dialog').find('div.modal-login').html(data);
	                login.resetPasswordCallback();
	            }
	        });
	    });
	},
	
	resetPassword: function() {
	    $('.login-passwordreset a').off('click').on('click', function (e) {
	        e.preventDefault();
			require('@foundation/js/aepdatacollection-util').coreCall('forgotPassword');
	        dialog.open({
	            url: $(e.target).attr('href'),
	            options: {
	            	dialogClass: 'forgot-pwd-dialog',
	                open: function () {
	                	login.resetPasswordCallback();
	                }
	            }
	        });
    	});
	},
	
	modalSignIn : function() {
	    $(document).on('click','.account-signin, .create-account', function(e) {
            var url = $(this).data('href') || $(this).prop('href');
			if (typeof url === 'undefined') {
                return;
			}
            e.preventDefault();
            var action = $(this);
	    	ajax.load({
	            url: url,
	            type: 'GET',
	            async: false,
	            callback: function(data) {

					if (enhancedSignin && data) {
						var modalClass = 'account-login-register-modal';
						var getSignInData = enhancedSignin.modal.isValid(data, modalClass);
						if (getSignInData) {
							return;
						}
					}

	            	if ($(data).find('.login-box-content').length > 0) {
	                    dialog.open({
	                        html: data,
	                        options: {
	                            title: Resources.SAVE_FAVORITES,
	                            width: 770,
	                            closeOnEscape: false,
	                            dialogClass: 'account-login-dialog',
	                            beforeClose: function () {
	                            	$("body").css("overflow","auto");
	                            	$("div.account-login-dialog").removeClass("fullHeight");
	                            },
	                            open: function() {
	                            	$("body").css("overflow","hidden");
	                                $('.account-login-dialog').css('overflow-y', 'auto').css('max-height', '100%');
	                            	function modalPopup() {
	                            		if($(window).width() < 959) {
		                        			if(navigator.platform == "iPhone") { $("div.account-login-dialog").removeClass("fullHeight");
		                        			} else { $("div.account-login-dialog").addClass("fullHeight");}
		                        		}else {
		                					$("div.account-login-dialog").removeClass("fullHeight");
		                    			}
	                            	}
	                            	modalPopup();
	                            	$(window).on('resize',function() {
	                            		modalPopup();
	                            	});
									validator.init();
									validator.passwordConstraints();
	                                login.resetPasswordModal();
									login.siginOverLay($(this));
									$('.oAuthIcon').on('click', function() {
										var form = $(this).closest('form');
										form.find('#OAuthProvider').val(this.id);
									});									
                        			tabs.init();
									if(action.hasClass('create-account')) {
										$('#createlogin-tab').trigger('click');
									}
	                            }
	                        }
	                    });
	            	} else if ($(data).find('.registered-nav-list').length > 0 && url.indexOf('wishlistHeader') > 0) {
	            		location.href = Urls.wishlistShow;
	            	}
	            }
	        });
	    });
	},
	
    /**
     * @private
     * @function
     * @description init events for the loginPage
     */
    init: function () {
    	login.resetPassword(); 
    	login.modalSignIn();
        //o-auth binding for which icon is clicked
        $('.oAuthIcon').on('click', function () {
            var form = $(this).closest('form');
			form.find('#OAuthProvider').val(this.id);
        });
        
        $('.accountcreationconf').on('click',function (e){
	        e.preventDefault();
	        var form=$(this).closest('form');
	        var password = $(".pwd").val();
	        var confirmpassword= $(".confirmpwd").val();
	        if (password !=confirmpassword){
	              $('.pswrd-mismatch').text(Resources.PASSWORD_MISMATCH_ERROR);
	              return false;
	        }
	        
	        var url = util.appendParamToURL(form.attr('action'), $(this).attr('name'), true);
	
	        url = util.appendParamToURL(url, form.find("input[name$=_securekey]").attr("name"), form.find("input[name$=_securekey]").attr("value"));
	  		  $.ajax({
	                  type: 'POST',
	                  data: form.serialize(),
	                  url:url,
	                  success: function(response) {
	                  	if(response.status === false){
	                  		$('.pswrd-mismatch').text(Resources.PASSWORD_INVALID_ERROR);
	                  		return false;
	                  	}else if(response.status === 'accountexist'){
	                  		$('.pswrd-mismatch').text(Resources.EXISTING_CUSTOMER);
	                  		return false;
	                  	}else{
	                  		page.redirect(response.redirectURL);
	                  		
	                  	}
	                  }
	              });
	    });
    

        //toggle the value of the rememberme checkbox
        $('#dwfrm_login_rememberme').on('change', function () {
            if ($('#dwfrm_login_rememberme').attr('checked')) {
                $('#rememberme').val('true');
            } else {
                $('#rememberme').val('false');
            }
        });
        $('input[name$="login_rememberme"]').on('change', function () {
            var form = $(this).closest('form');
            var formParent = $(form).parent().parent();
	
            if ($(this).is(':checked')) {
                formParent.find('#oauthrememberme').val('true');
                $('#rememberme').val('true');
            } else {
                formParent.find('#oauthrememberme').val('false');
                $('#rememberme').val('false');
            }
        });
		
        $('input[name$="profile_customer_addtoemaillist"]').on('change', function () {
            var form = $(this).closest('form');
            var formParent = $(form).parent().parent();
	
            if ($(this).is(':checked')) {
                formParent.find('#oauthaddtoemaillist').val('true');
            } else {
                formParent.find('#oauthaddtoemaillist').val('false');
            }
        });
    }
}

module.exports = login;
