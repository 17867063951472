'use strict';

var dialog = require('../dialog');
var util = require('../util');
var progress = require('../progress');

module.exports = function() {
    //Code for Email Signup Page
    $('#emailSignUpButton').on('click', function(e) {
        e.preventDefault();
        var $form = $('#emailSignupSubscribeForm');
        var emailSignupPage = $form.find('input[name$="_customerdetail_email"]').val();
        var emailSignupPageConfirm = $form.find('input[name$="_customerdetail_emailconfirm"]').val();
        $form.validate();
        if ($form.valid()) {
            if ((emailSignupPage.toLowerCase() == emailSignupPageConfirm.toLowerCase())) {
                $(document).find('#emailSignupSubscribeForm .email-conf-error').remove();
                $form.append('<input type="hidden" name="' + $('#emailSignUpButton').attr('name') + '" value="true" />');
                var url = $form.attr('action');
                $.ajax({
                    type: 'POST',
                    data: $form.serialize(),
                    url: url,
                    success: function(data) {
                        var dlg = dialog.create({
                            options: {
                                width: 650,
                                height: 'auto',
                                dialogClass: 'emailOnce'
                            }
                        });
                        var responseDivData = $(data).filter('.emailsignupform-content').html();
                        var emailonce = $('.emailonce').length;
                        if (emailonce < 1) {
                            $(dlg).append(responseDivData);
                        }
                        dlg.dialog('open');
                        $('#emailSignupSubscribeForm').trigger('reset');
                    }
                });
            } else {
                $('input[id$="_emailsignup_customerdetail_emailconfirm"]').after('<span class="email-conf-error error">Please enter the same value again</span>');
            }
        }
    });

    //Code for Email Signup Modal
    $('#emailSubmitModal').on('click', function(e) {
        e.preventDefault();
        var email = $('#email-signup-modal').val();
        var modalForm = $('#email-signup-modalForm');
        modalForm.validate();
        if (modalForm.valid()) {
            $.ajax({
                type: 'POST',
                url: util.appendParamsToUrl(Urls.emailSignupAjax, {
                    emailAddress: email
                }),
                success: function(data) {
                    if ($('.emailsignupform').length > 0) {
                        $('.emailsignupform .ui-dialog-titlebar-close').trigger('click');
                    }
                    var dlg = dialog.create({
                        options: {
                            width: 650,
                            height: 'auto',
                            dialogClass: 'emailOnce'
                        }
                    });
                    var responseDivData = $(data).filter('.emailsignupform-content').html();
                    var emailonce = $('.emailonce').length;
                    if (emailonce < 1) {
                        $(dlg).append(responseDivData);
                    }
                    dlg.dialog('open');
                }
            });
        }
    });
    
    //Code for Email Promotion Signup Modal
    $('#emailPromotionSubmitModal').on('click', function(e) {
        e.preventDefault();
        var email = $('#dwfrm_emailAddress').val();
        var emailSource = '', srcValue = '';
        var redirectlink = $(this).data('link');
        if($('#emailPromotionSource').length > 0) {
            emailSource = $('#emailPromotionSource').val();
         }
        if($('#emailPromotionSrcValue').length > 0) {
        	srcValue = $('#emailPromotionSrcValue').val();
         }
        
        var modalForm = $('#emailPromotionModalForm');
        modalForm.validate();
        if (modalForm.valid()) {
            progress.show($(this));
            $.ajax({
                type: 'POST',
                url: util.appendParamsToUrl(Urls.emailSignupAjax, {
                    emailAddress: email,
                    emailSource: emailSource,
                    srcValue: srcValue,
                    emailType: 'promotion'
                }),
                success: function(data) {
                    var responseDivData = $(data).find('.thank-you-msg').html();
                    if($('.content-container-form').length > 0) {
                    	$('.content-container-form').html(responseDivData).hide().fadeIn( "slow" );
                    	setTimeout(function(){ 
                    		if(redirectlink) {
                    			window.location.href = redirectlink;
                    		} else {
                    			location.reload();
                    		}
                    	}, 2000);
                    }
                }
            });
        }
    });
}
